/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
body {
  --graded: #ff7c00;
  --gradec: #ffc000;
  --gradeb: #c5e0b3;
  --gradea: #92d050;
  --grade: #82f802;
  --gradef: #ff0000;
  --critical: #92243e;
  --high: #de4a50;
  --medium: #f18c43;
  --low: #f8c851;
  --info: #67ace1;
  --not-applicable: #6d6d6d;
  --compliant: #61AD52;
}

#sSearchCompanyInput-panel {
    min-width: 220px !important;
    .mdc-list-item {
        max-height: 20px!important;
    }
}
.mat-form-field-required-marker { color: red !important; }
.mat-mdc-form-field-required-marker { color: red !important; }

#app {
  display: flex;
  flex-direction: row;
  // height: 100vh;
}

#list-host {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: 100%;
  background-color: #f1f1f1;
  overflow: hidden;
}

.dark-yellow #list-host {
  background-color: #3b3c3f;
  color: #e6e6e6;
}

#designer-host,
#viewer-host {
  flex: 1 1 auto;
}
.list-title {
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  height: 52px;
  margin: 0 10px;
  padding: 0 2px;
  user-select: none;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.eval-text {
    display: none !important;
}
#appbar_arjs-designer-0_main-app-bar_control_Item_0 {
    display: none !important;
}
.gc-menu__logo {
    display: none !important;
}
.arjs-reportPage > div.arjs-textBoxItem {
    display: none !important;
}
_:-ms-fullscreen, :root .highcontrast .e-userimg.sf-icon-Male::before,
_:-ms-fullscreen, :root .highcontrast .e-userimg.sf-icon-FeMale::before{
  color: black;
}
:host-context(.highcontrast)  .e-userimg.sf-icon-Male::before,
:host-context(.highcontrast)  .e-userimg.sf-icon-FeMale::before{
  color: black;
}
#Emptext{
top: -12px;
left: 41px;
position: relative;
}
#overviewgrid.e-rtl #Emptext{
  top: -12px;
  left: -41px;
  position: relative;
}

.empimg{
    height: 27px;
    width: 29px;
    border-radius: 14px;
    background-color:#e3165b;
}
:host-context(.bootstrap)   .empimg{
  background-color:#0078d6;
}
:host-context(.bootstrap4)   .empimg{
  background-color:#0078d6;
}
:host-context(.bootstrap5)   .empimg{
  background-color:#0078d6;
}
:host-context(.fabric)  .empimg {
  background-color:#0078d6;
}
:host-context(.material)   .empimg{
  background-color:#e3165b !important;
}
:host-context(.highcontrast)  .empimg{
  background-color:#ffd939;
}
:host-context(.tailwind)   .empimg{
  background-color:#4f46e5;
}
:host-context(.fluent)   .empimg{
  background-color:#106EBE;
}

.e-userimg.sf-icon-Male::before,
.e-userimg.sf-icon-FeMale::before{
font-size: 13px;
}

.e-userimg.sf-icon-Male, .e-userimg.sf-icon-FeMale {
margin: 0px 8px;
line-height: 26px;
color: white;
font-size: 13px;
}

.e-userimg.sf-icon-Male::before{
  content: '\e700';
}
.e-userimg.sf-icon-FeMale::before{
  content: '\e701';
}
:host-context(.highcontrast)  #overviewgrid td.e-rowcell.e-selectionbackground.e-active {
  background-color: transparent;
  color: white;
}

.e-bigger #overviewgrid .statustxt{
  position: relative;
}

#overviewgrid ::ng-deep td.e-rowcell.e-selectionbackground.e-active {
  background-color: transparent;
}

.e-image {
  height: 13px;
  width: 14px;
}

td.e-rowcell .statustxt.e-activecolor {
  color: #00cc00;
  position: relative;
  top: 9px;
}

.bootstrap5 td.e-rowcell .statustxt.e-inactivecolor,
.bootstrap5 td.e-rowcell .statustxt.e-activecolor {
  top: 8px;
}

.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-activecolor,
.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-inactivecolor {
  position: relative;
  top: 1.5px;
}

.e-bigger.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-activecolor,
.e-bigger.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-inactivecolor {
  top: -0.5px;
}

.bootstrap5 .e-checkboxfiltertext #Trusttext {
  margin-left: -4px;
}

td.e-rowcell .statustxt.e-inactivecolor {
  color: #e60000;
  position: relative;
  top: 9px;
}

.statustemp.e-inactivecolor {
  background-color: #ffd7cc;
  width: 64px;
}

.statustemp.e-activecolor {
  background-color: #ccffcc;
  width: 57px;
}

.statustxt.e-activecolor {
  color: #00cc00;
  position: relative;
  top: 9px;
}

.statustxt.e-inactivecolor {
    color: #e60000;
    position: relative;
    top: 9px;
}
.statustemp {
  position: relative;
  height: 19px;
  border-radius: 5px;
  text-align: center
}

:host-context(.highcontrast)  #msg {
  color: #FFFFFF
}

#msg {
  font-family: "Segoe UI", "GeezaPro", "DejaVu Serif", sans-serif;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.95;
  color: #333;
  float: right;
  margin-top: -24px;
}

@media only screen and (max-width: 370px) {
  #msg {
      float: left;
      margin-top: 3px;
  }
  #overviewgrid{
      margin-top:36px;
  }
}

#samplecontainer {
  padding-top: 5px;
}

#myProgress {
  position: relative;
  height: 18px;
  width: 10em;
  text-align: left;
  background-color: white;
}
:host-context(.highcontrast)  #overviewgrid #myProgress{
  background-color: black;
}

#myBar {
  position: absolute;
  width: 10%;
  height: 100%;
  background-color: #00b300;
}
#myBar.progressdisable {
  background-color: #df2222;
}
#pbarlabel {
  position: relative;
  left:10px;
  line-height: 18px;
  font-size: 10px;
  color: white;
}

.custom-rating.e-rating-container .e-rating-item-container ,
.e-bigger .custom-rating.e-rating-container .e-rating-item-container .e-rating-icon,
.e-bigger.custom-rating.e-rating-container .e-rating-item-container .e-rating-icon {
    font-size: 11px;
}

.custom-rating.e-rating-container .e-rating-item-container .e-rating-icon {
  -webkit-text-fill-color: lightgrey;
  -webkit-text-stroke: 1px lightgrey;
}

.custom-rating.e-rating-container .e-rating-item-container.e-rating-selected .e-rating-icon {
  background: linear-gradient(to right, #ffa600 var(--rating-value), #ffa600 var(--rating-value));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #ffa600;
}
#PivotView {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (max-width: 400px) {
  #pivot-table-section {
    overflow: auto;
  }
}

.sb-sample-content-area {
  min-height: 255px !important;
}

.control-section {
  min-height: 255px !important;
}

#PivotViewcontainerwrapper {
  height: auto !important;
}

.e-hyperlinkcell {
  text-decoration: auto;
}

.e-hyperlinkcell:hover {
  text-decoration: underline;
}

.university-logo {
    width: 30px;
    aspect-ratio: auto 30 / 30;
    height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.urllink {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 12px;
}
.orientationcss .e-headercelldiv {
    /*transform: rotate(90deg) !important;
   overflow: visible !important;*/
    transform: rotate(280deg) !important;
    overflow: visible !important;
    position: relative;
    top: 90%;
    right: 10%;
}
.e-grid .e-headercell .orientationcss{
  padding: 21px 21px 0 !important;
  vertical-align: top;
}
.e-grid .e-headercell .e-columnmenu {
  margin: -18.5px -30px !important;
}
.mat-accordion .mat-expansion-panel {
  margin-bottom: 1px !important;
}
.e-grid .e-rowcell {
  padding: 8px 8px !important;
}
.e-grid .e-headercell, .e-grid .e-detailheadercell {
    padding: 0 8px 0 !important;
}




.cs-critical { background-color: var(--critical) !important; color: white !important; }
.cs-high { background-color: var(--high) !important; color: white !important; }
.cs-medium { background-color:  var(--medium) !important; color: black !important; }
.cs-low { background-color: var(--low) !important; color: black !important; }
.cs-info { background-color: var(--info) !important; color: black !important; }

    .tc-critical{ color: var(--critical) !important; }
    .tc-high{ color: var(--high) !important; }
    .tc-medium{ color: var(--medium) !important; }
    .tc-low{ color: var(--low) !important; }
    .tc-info{ color: var(--info) !important; }

.t-gray{color: var(--gray);}
.t-c{color: var(--critical);}
.t-h{color: var(--high);}
.t-m{color: var(--medium);}
.t-l{color: var(--low);}
.t-i{color: var(--info);}
.t-d{color: var(--not-applicable);}
.t-g{color: var(--compliant);}

.gradef,.gradef:hover{color:#fff!important;background-color:var(--gradef)!important}
.graded,.graded:hover{color: #444444 !important;background-color: var(--graded) !important}
.gradec,.gradec:hover{color: #444444 !important;background-color:var(--gradec)!important}
.gradeb,.gradeb:hover{color: #444444 !important;background-color:var(--gradeb)!important}
.gradea,.gradea:hover{color: #444444 !important;background-color:var(--gradea)!important}
.grade,.grade:hover{color: #444444 !important;background-color:var(--gradea)!important}

.spacer { flex: 1 1 auto; }

.mini-form-field {transform: translateY(0em) scale(0.8) !important;}
.imageWidth { width: 17px !important;  margin-left: 9px; }
.imageWidthDate { width: 25px !important;  margin-left: 5px; }
.vendor-logo { width: 18px; }

.card-active {
    border-color: var(--fuse-primary);
    box-shadow: 0 6px 13px #c3c3e1bf;
    border-width: 1px;
}
.btn-active {
  border-color: var(--fuse-primary);
  border-width: 1px;
  border-radius: 20px;
  padding: 0 10px;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    min-height: 33px !important;
    max-height: 45px !important;
}
/*.mat-toolbar-row, .mat-toolbar-single-row {
    height: 50px !important;
}*/

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}
@media screen and (min-width: 757px) and (max-width: 1440px) {
  .checkcompanyfield {
    @apply w-50;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1600px) {
  .checkcompanyfield {
    @apply w-90;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
  .checkcompanyfield {
    @apply w-120;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
  .checkcompanyfield {
    @apply w-140;
  }
}

@media screen and (min-width: 1921px) and (max-width: 2160px) {
  .checkcompanyfield {
    @apply w-160;
  }
}

@media screen and (min-width: 2161px) and (max-width: 2880px) {
  .checkcompanyfield {
    @apply w-180;
  }
}

@media screen and (min-width: 2881px) {
  .checkcompanyfield {
    @apply w-192;
  }
}

@media screen and (min-width: 1440px) {
  .checkcompanyfieldWid{
    @apply w-120;
  }
}

@media screen and (min-width: 1801px) {
  .checkcompanyfieldWid{
    @apply w-192;
  }
}